import { QuestionOption, SurveyQuestionModel } from '../app.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, Sanitizer, Inject, ViewEncapsulation, Renderer2, } from '@angular/core';
import { AppService } from '../app.service';
import { Title, DomSanitizer, DOCUMENT } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { SurveyService } from '../survey/survey.service';
import Swal from 'sweetalert2';
import { PageScrollService } from 'ngx-page-scroll-core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.css']
})
export class KioskComponent implements OnInit {


  @ViewChild('countdown') counter: CountdownComponent;

  config: any;

  public searchValue = '';

  public RemainingTime;
  public actualTime;
  public surveyData: any;
  public isSubmitted = false;
  public surveyLinkId = '';
  public invitationDetails: any;
  public currentQuestionNumber = 1;
  public defaultColor = 'rgb(245, 128, 38)';
  public defaultBtnBgColor = 'rgba(245, 128, 38, 0.2)';
  public currentStatus: 'already-completed' | 'invalid-link' | 'survey-closed' | 'survey-completed' | 'select-language' | 'instruction' | 'question-answer' | 'timed-out';


  constructor(private routeParams: ActivatedRoute,
    private appService: AppService,
    private title: Title,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private surveyService: SurveyService,
    private pageScrollService: PageScrollService,
    private _renderer2: Renderer2,
    private router: Router,
    private breakPointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document) {
    this.routeParams.params.subscribe(
      response => {
        this.surveyLinkId = response.id || '';
        this.getSurveyByKioskLinkId();
      });
  }

  ngOnInit() {
    this.invitationDetails = {};
  }

  checkIsMobile() {
    return this.breakPointObserver.isMatched('(max-width: 768px)');
  }

  public getSurveyByKioskLinkId() {
    this.appService.getSurveyByKioskLinkId(this.surveyLinkId).subscribe(
      response => {
        if (response === 'already completed') {
        Swal.fire("You have already submitted the survey");  
        } else if (response === 'no') {
          Swal.fire("Invalid Link");
        } else if (response === 'closed') {
          Swal.fire("This survey is closed");
        }
        else {
          this.surveyData = response;

          this.currentStatus = 'select-language';
          if (this.surveyData.colorCode && this.surveyData.colorCode !== '') {
            this.defaultColor = 'rgb(' + this.surveyData.colorCode + ')';
            this.defaultBtnBgColor = 'rgba(' + this.surveyData.colorCode + ', 0.2)';

            this.sanitizer.bypassSecurityTrustStyle("--default-color: " + this.defaultColor);
            this.sanitizer.bypassSecurityTrustStyle("--default-btn-bg-color: " + this.defaultBtnBgColor);
          }

          if (this.surveyData.title) {
            this.title.setTitle(this.surveyData.title);
          }
        }
      },
      error => {
        Swal.fire("Invalid Link");
      });
  }

  start() {
    this.appService.getSurveyByLinkId(this.searchValue).subscribe(
      response => {
        if (response === 'already completed') {
          Swal.fire("You have already submitted the survey");
        } else if (response === 'no') {
          Swal.fire("Invalid Invitation Code");
        } else if (response === 'closed') {
          Swal.fire("This survey is closed");
        }
        else {
          this.router.navigate(['./' + this.searchValue ])
        }
      },
      error => {
        this.currentStatus = 'invalid-link';
      });
  }
}

