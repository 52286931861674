export class DashboardData {
    surveyUId: string;
    surveyCode: string;
    formFields: DashboardDataFormField[];
    constructor() {
        this.surveyUId = '';
        this.surveyCode = '';
        this.formFields = [];
    }
}

export class DashboardDataFormField {
    index: number;
    label: string;
    usedForFilter: boolean;
    options : []
    constructor(index: number, label : string, usedForFilter : boolean ) {
        this.index = index;
        this.label= label;
        this.usedForFilter = usedForFilter;
        this.options = [];
    }
}

