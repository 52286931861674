import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  public searchValue = '';

  constructor(private route: Router) { }


  ngOnInit() {
  }

  start() {
    if (this.searchValue != '') {
      this.route.navigate(['./' + this.searchValue]);
    }
  }
}
