import { QuestionOption, SurveyQuestionModel } from '../app.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, } from '@angular/core';
import { AppService } from '../app.service';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { SurveyService } from '../survey/survey.service';

@Component({
  selector: 'app-take-survey-old',
  templateUrl: './take-survey-old.component.html',
  styleUrls: ['./take-survey-old.component.css']
})
export class TakeSurveyOldComponent implements OnInit {

  invCode: any;
  public tempVar = [];
  public responses: Response[] = [];
  public surveyLinkId;
  public surveyUId;
  public surveyData: any;
  public invitationDetails: any;
  public imageSurveyuId;
  public selectedlanguage;
  public surveyEnable = false;
  icode: any;
  // public isAnonymous = false;
  public surveyQuestionSet: any;
  // public currentPageQuestions: SurveyQuestionModel[] = [];
  public currentPageNumber = 1;
  public totalPages = 0;
  public currentPageNumberSet = false;

  invitationCode: string;
  responseVia: string;
  public nextBtnHidden = false;
  public isNextbuttonEnabled: boolean = false;
  public spinner;

  public isSubmitted: boolean = false;

  public surveyClosed = false;
  public surveyCompleted = false;
  public alreadyCompleted = false;
  public invalidLink = false;
  public surveyCompleteProgressbar = false;
  public closeImage: any;

  // public timers = [3600, 3600, 0];
  public timers = [];
  public testStartTime = 0;
  public continuousFunction;
  public enableTimer = false;
  public testTimeTaken = 0;

  public instructionData;
  currentInstructionData: []
  currentTimeData: []
  public time;


  public timerConfig = {
    leftTime: 0,
    notify: [120],
    template: '$!h!:$!m!:$!s!'
  }


  languages = [
    { value: 'English', viewValue: 'English' },
    { value: 'Hindi', viewValue: 'हिंदी' },
    { value: 'Marathi', viewValue: 'मराठी' },
    { value: 'Kannada', viewValue: 'ಕನ್ನಡ' },
    { value: 'Bengali', viewValue: 'Bengali' },
    { value: 'Gujrati', viewValue: 'ગુજરાતી' },
    { value: 'Tamil', viewValue: 'தமிழ்' },
    { value: 'Arabic', viewValue: 'Arabic' },
    { value: 'Telugu', viewValue: 'తెలుగు' },
    { value: 'Malayalam', viewValue: 'മലയാളം' }
  ];


  @ViewChild('instructionsModal') instructionsModal: TemplateRef<any>;
  currentTime: never;


  constructor(private routeParams: ActivatedRoute, private appService: AppService, private title: Title, public dialog: MatDialog, private surveyService: SurveyService, private router: Router) {
    this.routeParams.params.subscribe(
      response => {
        this.surveyLinkId = response.id;
      });
  }


  ngOnInit() {
    this.getSurveyData();
    this.invitationDetails = {};
  }

  ngOndestroy() {
    if (this.continuousFunction) {
      clearInterval(this.continuousFunction);
    }
  }
  public getLanguageText(lang) {
    let text = '';
    text = this.languages.find(d => d.value === lang).viewValue;
    return text;
  }



  public getSurveyData() {
    const linkid = this.surveyLinkId;
    this.appService.getSurveyByLinkId(linkid).subscribe(
      response => { 
        if (response['message'] === 'closed' || response['message'] === 'already completed' || response['message'] === 'no') {
          this.appService.GetSurveyCloseImageByLinkId(linkid).subscribe(
            res => {
              this.closeImage = res;
              this.imageSurveyuId = this.closeImage.uId;
              this.surveyClosed = res['isClosed'];
  
              if (response['message'] === 'already completed') {
                this.alreadyCompleted = true;
              } else if (response['message'] === 'no') {
                this.invalidLink = true;
              }
            },
            error => {
              console.error('Error in getting survey close image:', error);
            });
        } else {
          this.surveyData = response;
          if (response['useNewDesign'] === true) {
            this.router.navigate([this.surveyLinkId]);
          }
  
          if (!this.surveyData.isAnonymous) {
            if (this.surveyData.linkId === linkid) {
              this.invalidLink = true;
            }
          }
  
          if (!this.surveyData.isWeb) {
            this.invalidLink = true;
          }
  
          if (this.surveyData.title) {
            this.title.setTitle(this.surveyData.title);
          }
  
          if (this.surveyData.languages.length > 0) {
            this.selectedlanguage = this.surveyData.languages[0];
          }
  
          if (this.surveyData.customizedMessage && !this.surveyData.isAnonymous) {
            this.appService.getInvitationDetails(this.surveyLinkId).subscribe(
              response1 => {
                this.invitationDetails = response1;
              },
              error => {
                console.error('Error in getting invitation details:', error);
              }
            );
          }
  
          this.imageSurveyuId = this.surveyData.uId;
        }
      },
      error => {
        console.error('Error in getting survey data:', error);
      }
    );
  }
  

  public getQuestionSet() {
    this.imageSurveyuId = this.surveyData.uId;
    this.appService.getSurveyQuestionSet(this.surveyData.uId).subscribe(
      response => {

        // let timeRemaining = this.timers[this.currentPageNumber - 1];
        // let timeTaken = '';

        this.surveyQuestionSet = response;
        this.surveyEnable = true;
        this.surveyQuestionSet.questions.sort((a, b) => {
          return a.order > b.order;
        });

        this.surveyQuestionSet.questions.forEach(q => {
          if (q.pageNumber > this.totalPages) {
            this.totalPages = q.pageNumber;
          }
        });

        // if not anonymousvey
        if (!this.surveyData.isAnonymous) {
          // get the saved answers
          this.appService.getSurveyQuestionSetResponseByInvitationCode(this.surveyLinkId).subscribe(
            response1 => {
              if (response1 != null) {
                let qResponses = response1['responses'];
                let timeTaken = '';
                let pageOfTimeTaken = 0;
                qResponses.forEach(responseElement => {
                  const selectedQuestion = this.surveyQuestionSet.questions.find(q => q.code === responseElement.questionCode);
                  if (responseElement.responseCode !== '' && responseElement.responseCode !== null) {
                    if (selectedQuestion != null) {
                      selectedQuestion.response = responseElement.responseCode;
                      if (selectedQuestion.type === 'multi-text-mandatory') {
                        let responses = responseElement.responseCode.split('#');
                        for (let i = 0; i < selectedQuestion.options.length; i++) {
                          selectedQuestion.options[i].response = responses[i];
                          if (selectedQuestion.options[i].response === null || selectedQuestion.options[i].response.trim() === '') {
                            this.currentPageNumber = selectedQuestion.pageNumber;
                            this.currentPageNumberSet = true;
                          }
                        }
                      }
                      if (this.surveyData.showTimer) {
                        selectedQuestion.timeTaken = responseElement.timeTaken;
                        timeTaken = responseElement.timeTaken;
                        pageOfTimeTaken = selectedQuestion.pageNumber;
                      }
                    }
                  } else {
                    if (selectedQuestion.pageNumber >= this.currentPageNumber && !this.currentPageNumberSet) {
                      this.currentPageNumber = selectedQuestion.pageNumber;
                      this.currentPageNumberSet = true;
                      if (this.currentPageNumber > pageOfTimeTaken) {
                        timeTaken = '';
                      }
                    }
                  }
                });
                if (timeTaken !== '') {
                  this.testTimeTaken = Number(timeTaken);
                  console.log('time taken', this.testTimeTaken);

                }

                if (this.surveyData.showInstructions) {
                  this.showInstruction();
                } else if (this.surveyData.showTimer) {
                  this.startTimer();
                }
              } else {
                if (this.surveyData.showInstructions) {
                  this.showInstruction();
                } else if (this.surveyData.showTimer) {
                  this.startTimer();
                }
              }
            },
            error1 => {
              // console.log('Error in getting data');
              // console.log(error1);
            }
          );
        } else {
          if (this.surveyData.showInstructions) {
            this.showInstruction();
          } else if (this.surveyData.showTimer) {
            this.startTimer();
          }
        }
      },
      error => {
        // console.log('Error in getting data');
        // console.log(error);
      });
  }

  public showInstruction() {
    // getAllSurveyInstructions api called
    this.surveyService.getAllSurveyInstructions(this.surveyData.uId).subscribe(
      response => {
        this.instructionData = response;
        //filter for current page === instructions page added
        this.currentInstructionData = this.instructionData.filter(d =>
          parseInt(d.pageNumber) === this.currentPageNumber);
      })
    this.dialog.open(this.instructionsModal, { width: '70%', height: '90%' }).afterClosed().subscribe(result => {
      if (this.surveyData.showTimer) {
        this.startTimer();
        this.updateAnswerInterval();
      }
    });
  }

  public startTimer() {
   // for loop to push individual page  time
    this.instructionData.forEach(page => {
      // this.timers=[]
      this.timers.push((page['time'] * 60));
      this.timerConfig.leftTime = this.timers[this.currentPageNumber - 1] - this.testTimeTaken;
      console.log("this.testTimeTaken" ,this.testTimeTaken);
      console.log(this.timers, this.timerConfig);
      this.enableTimer = true;
      let date = new Date();
      // let startTime = date.getTime() / 1000;
      let startTime = page['time'] * 60;
      this.testStartTime = startTime - this.testTimeTaken;
      window.scrollTo(0, 0);
    });
  }

  public handleEvent(event) {
    if (event.action == 'finished') {
      if (!this.isMaxQuestions()) {
        this.goToNext();
      } else {
        this.SubmitTest();
      }
    } else if (event.action === 'notify') {
      alert("You have 2 minutes remaining.");
    }
  }

  public getCustomizedMessage() {
    let customizedMessageText = '';
    if (this.surveyData.customizedMessage) {
      customizedMessageText = this.surveyData.customizedMessageText;

      customizedMessageText = customizedMessageText.replace('###Name###', this.invitationDetails.name);
      customizedMessageText = customizedMessageText.replace('###Email###', this.invitationDetails.email);
      customizedMessageText = customizedMessageText.replace('###Phone###', this.invitationDetails.phone);
      customizedMessageText = customizedMessageText.replace('###EmployeeCode###', this.invitationDetails.employeeCode);
      customizedMessageText = customizedMessageText.replace('###Department###', this.invitationDetails.department);
      customizedMessageText = customizedMessageText.replace('###Location###', this.invitationDetails.location);
      customizedMessageText = customizedMessageText.replace('###CompanyName###', this.invitationDetails.companyName);
      customizedMessageText = customizedMessageText.replace('###ReportsTo###', this.invitationDetails.reportsTo);
      customizedMessageText = customizedMessageText.replace('###Column1###', this.invitationDetails.column1);
      customizedMessageText = customizedMessageText.replace('###Column2###', this.invitationDetails.column2);
      customizedMessageText = customizedMessageText.replace('###Column3###', this.invitationDetails.column3);
      customizedMessageText = customizedMessageText.replace('###Column4###', this.invitationDetails.column4);
      customizedMessageText = customizedMessageText.replace('###Column5###', this.invitationDetails.column5);
      customizedMessageText = customizedMessageText.replace('###Column6###', this.invitationDetails.column6);
      customizedMessageText = customizedMessageText.replace('###Column7###', this.invitationDetails.column7);
      customizedMessageText = customizedMessageText.replace('###Column8###', this.invitationDetails.column8);
      customizedMessageText = customizedMessageText.replace('###Column9###', this.invitationDetails.column9);
      customizedMessageText = customizedMessageText.replace('###Column10###', this.invitationDetails.column10);
    }
    return customizedMessageText;
  }

  public updateAnswerInterval() {
    if (this.continuousFunction) {
      clearInterval(this.continuousFunction);
    }
    this.continuousFunction = setInterval(() => {
      this.saveAnswersInIntervals();
    }, 60000);
  }

  public saveAnswersInIntervals() {
    let responses: Response[] = [];
    let timeTaken = 0;
    if (this.surveyData.showTimer) {
      let date = new Date();
      let stopTime = date.getTime() / 1000;
      timeTaken = Math.ceil(stopTime - this.testStartTime);
    }
    this.surveyQuestionSet.questions.forEach(d => {
      const response = new Response();
      response.questionCode = d.code;
      response.responseCode = d.response;
      if (this.surveyData.showTimer) {
        if (d.pageNumber === this.currentPageNumber) {
          d.timeTaken = timeTaken.toString();
          response.timeTaken = timeTaken.toString();
        } else {
          response.timeTaken = d.timeTaken;
        }
      }
      responses.push(response);
    });
    const responsequestionSet = {
      surveyUId: this.surveyData.uId,
      clientUId: this.surveyData.clientUId,
      invitationCode: '',
      responseVia: 'web',
      responses: responses
    };

    if (!this.surveyData.isAnonymous) {
      responsequestionSet.invitationCode = this.surveyLinkId;
    }
    this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
      response => {
        // console.log('Questionset Posted');
        // console.log(response);
      }, error => {
        // console.log('API ERROR:', error);
      });
  }

  public goToNext() {
    if (this.surveyData.showTimer && this.continuousFunction) {
      clearInterval(this.continuousFunction);
    }

    if (!this.surveyData.isAnonymous) {
      let responses: Response[] = [];
      let timeTaken = 0;
      if (this.surveyData.showTimer) {
        let date = new Date();
        let stopTime = date.getTime() / 1000;
        timeTaken = Math.ceil(stopTime - this.testStartTime);
      }
      this.surveyQuestionSet.questions.forEach(d => {
        const response = new Response();
        response.questionCode = d.code;
        response.responseCode = d.response;
        if (this.surveyData.showTimer) {
          if (d.pageNumber === this.currentPageNumber) {
            d.timeTaken = timeTaken.toString();
            response.timeTaken = timeTaken.toString();
          } else {
            response.timeTaken = d.timeTaken;
          }
        }
        responses.push(response);
      });
      const responsequestionSet = {
        surveyUId: this.surveyData.uId,
        clientUId: this.surveyData.clientUId,
        invitationCode: '',
        responseVia: 'web',
        responses: responses
      };
      if (!this.surveyData.isAnonymous) {
        responsequestionSet.invitationCode = this.surveyLinkId;
      }
      this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
        response => {
          this.viewNextPage();
        }, error => {
          // console.log('API ERROR:', error);
        });
    } else {
      this.viewNextPage();
    }
  }

  public viewNextPage() {
    this.currentPageNumber += 1;
    this.testTimeTaken = 0;
    this.enableTimer = false;
    this.nextBtnHidden = this.isMaxQuestions();
    if (this.surveyData.showInstructions) {
      this.showInstruction();
    } else if (this.surveyData.showTimer) {
      this.startTimer();
    }
    window.scrollTo(0, 0);
  }

  getQuestionText(question: SurveyQuestionModel) {
    let questionText = '';

    if (question.dependentOn > 0) {
      if (this.selectedlanguage !== 'English') {
        if (question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
          questionText = question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response).text + ' (';
        }
      }

      if (question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
        questionText = questionText + question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response).text;
      }

      if (this.selectedlanguage !== 'English' && questionText !== '') {
        questionText = questionText + ')';
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        questionText = question.texts.find(t => t.language === this.selectedlanguage).text + ' (';
      }

      questionText = questionText + question.texts.find(t => t.language === 'English').text;

      if (this.selectedlanguage !== 'English') {
        questionText = questionText + ')';
      }
    }
    return questionText;
  }

  clearDependentAnswer(question) {
    if (this.surveyQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order) !== undefined) {
      this.surveyQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order).response = '';
    }
  }

  getQuestionOptionText(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    let otherLanguageOptionText = '';
    let englishText = '';
    if (question.dependentOn > 0) {
      if (this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response !== option.ifPreviousAnswer) {
        return '';
      } else {
        if (this.selectedlanguage !== 'English') {
          optionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text + ' (';
        }
        optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
        if (this.selectedlanguage !== 'English') {
          optionText = optionText + ')';
        }
        return optionText;
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        otherLanguageOptionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text.toString();
      }
      englishText = optionText.toString() + option.languageOptions.find(o => o.language === 'English').text.toString();
      if (this.selectedlanguage !== 'English') {
        optionText = '\u202A' + otherLanguageOptionText + '\u202C' + "  ( " + englishText + " )";
      } else {
        optionText = englishText;
      }
      return optionText;
    }
  }

  getQuestionOptionTextInEnglish(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
    return optionText;
  }

  getQuestionOptionValue(option: QuestionOption) {
    let optionValue = '';
    optionValue = option.languageOptions.find(o => o.language === 'English').text;
    return optionValue;
  }

  checkboxOptionChanged(e, q, o) {
    if (e.target.checked) {
      if (q.response !== undefined) {

        var n = this.occurrences(q.response, '##', false);

        if (n < q.minSelect || n < q.maxSelect) {
          q.response = q.response + e.target.value + '##';

        } else {
          e.target.checked = false;
        }
      } else {
        q.response = e.target.value + '##';
      }
      // console.log(q.response);
    } else {
      q.response = q.response.replace(e.target.value + '##', '');
      // console.log(q.response);
    }
  }

  occurrences(string, subString, allowOverlapping) {
    string += "";
    subString += "";
    if (subString.length <= 0) return (string.length + 1);

    var n = 0,
      pos = 0,
      step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  isThisChecked(q, option) {
    if (q.response !== undefined) {
      // console.log(q);
      // console.log(option);

      let optionValue = '';
      optionValue = option.languageOptions.find(o => o.language === 'English').text;

      if (q.response.indexOf(optionValue) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  isMaxQuestions() {
    return this.surveyQuestionSet.questions.every(d => d.pageNumber <= this.currentPageNumber);
  }

  isSubmitEnabled() {
    let shouldSubmitBeEnabled = true;

    for (let i = 0; i < this.surveyQuestionSet.questions.length; ++i) {
      if (this.surveyQuestionSet.questions[i].response === undefined || this.surveyQuestionSet.questions[i].response === '' || this.surveyQuestionSet.questions[i].response === null) {
        shouldSubmitBeEnabled = false;
        break;
      }
    }
    return shouldSubmitBeEnabled;
  }

  isNextEnabled(currentPageNumber) {
    let shouldNextBeEnabled = true;

    for (let i = 0; i < this.surveyQuestionSet.questions.length; ++i) {
      if (this.surveyQuestionSet.questions[i].pageNumber === currentPageNumber) {
        if (this.surveyQuestionSet.questions[i].type !== 'multi-text-mandatory') {
          if (this.surveyQuestionSet.questions[i].response === undefined || this.surveyQuestionSet.questions[i].response === '' || this.surveyQuestionSet.questions[i].response === null) {
            if (this.getQuestionText(this.surveyQuestionSet.questions[i]).toLowerCase() !== 'not applicable') {
              shouldNextBeEnabled = false;
              break;
            }
          }
        } else {
          this.surveyQuestionSet.questions[i].options.forEach(o => {
            if (o.response === undefined || o.response.trim() === '' || o.response === null) {
              shouldNextBeEnabled = false;
            }
          });
        }
      }
    }
    return shouldNextBeEnabled && !this.isMaxQuestions();
  }

  goToPrevious(count) {
    window.scrollTo(0, 0);
    this.currentPageNumber -= 1;
    this.nextBtnHidden = this.isMaxQuestions();
  }

  markMultitextResponed(question) {
    let responseString = '';
    question.options.forEach(o => {
      if (o.response !== undefined && o.response.trim() !== '' && o.response !== null) {
        responseString = responseString + o.response + '#';
      } else {
        responseString = responseString + ' ' + '#';
      }
    });
    question.response = responseString;
  }

  SubmitTest() {
    if (!this.isSubmitted) {
      this.isSubmitted = true;
      if (this.surveyQuestionSet.questions.every(d => d.response !== '')) {
        // console.log(this.surveyQuestionSet);
        let responses: Response[] = [];
        let timeTaken = 0;
        if (this.surveyData.showTimer) {
          let date = new Date();
          let stopTime = date.getTime() / 1000;
          timeTaken = Math.ceil(stopTime - this.testStartTime);
        }
        this.surveyQuestionSet.questions.forEach(d => {
          const response = new Response();
          response.questionCode = d.code;
          response.responseCode = d.response;
          response.otherResponseText = d.otherResponseText;
          if (this.surveyData.showTimer) {
            if (d.pageNumber === this.currentPageNumber) {
              d.timeTaken = timeTaken.toString();
              response.timeTaken = timeTaken.toString();
            } else {
              response.timeTaken = d.timeTaken;
            }
          }
          responses.push(response);
        });
        const responsequestionSet = {
          surveyUId: this.surveyData.uId,
          clientUId: this.surveyData.clientUId,
          invitationCode: '',
          responseVia: 'web',
          responses: responses
        };

        if (!this.surveyData.isAnonymous) {
          responsequestionSet.invitationCode = this.surveyLinkId;
        }

        if (this.continuousFunction) {
          clearInterval(this.continuousFunction);
        }

        this.appService.SubmitQuestionSetResponses(responsequestionSet).subscribe(
          response => {
            const message = (response as { message: string }).message;
            this.isSubmitted = false;
            this.surveyCompleteProgressbar = true;
            if (response) {
              this.surveyCompleteProgressbar = false;
              this.surveyCompleted = true;
            }
          }, error => {
            this.isSubmitted = false;
            // console.log('API ERROR:', error);
          });
      } else {
        this.isSubmitted = false;
        alert('please answer all questions');
      }
    }
  }
}

class Response {
  questionCode: string;
  responseCode: string;
  otherResponseText: string;
  timeTaken: string;
}
