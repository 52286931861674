import { QuestionOption, SurveyQuestionModel } from '../app.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, Sanitizer, Inject, ViewEncapsulation, Renderer2, } from '@angular/core';
import { AppService } from '../app.service';
import { Title, DomSanitizer, DOCUMENT } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { SurveyService } from '../survey/survey.service';
import Swal from 'sweetalert2';
import { PageScrollService } from 'ngx-page-scroll-core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CountdownComponent } from 'ngx-countdown';


@Component({
  selector: 'app-take-survey',
  templateUrl: './take-survey.component.html',
  styleUrls: ['./take-survey.component.css']
})
export class TakeSurveyComponent implements OnInit {

  @ViewChild('countdown') counter: CountdownComponent;

  config: any;

  public loading = false;

  public hardCodedQuestionSurveyUId = 'e04dec5d-326d-4c15-9552-7dc26a09c026';
  public hardCodedQuestionNo = 200;
  public hardCodedQuestionAns = "";
  public hardCodedQuestionMCQAns = "";
  public hardCodedQuestionOtherAns = "";
  public hardCodedQuestionYesOptions1 = [
    { text: 'Strongly Agree', language: 'English', value: 'Strongly Agree' },
    { text: 'दृढ़तापूर्वक सहमत', language: 'Hindi', value: 'Strongly Agree' },
    { text: 'पूर्णपणे सहमत', language: 'Marathi', value: 'Strongly Agree' },
    { text: 'પુરી રીતે સહમત', language: 'Gujrati', value: 'Strongly Agree' },
    { text: 'ಧೃಡವಾಗಿ  ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೇನೆ ', language: 'Kannada', value: 'Strongly Agree' },
    { text: 'ৃঢ়ভাবে সম্মত হন', language: 'Bengali', value: 'Strongly Agree' },
    { text: 'உறுதியாக ஏற்கிறேன்', language: 'Tamil', value: 'Strongly Agree' },
    { text: 'గట్టిగా అంగీకరిస్తున్నాను', language: 'Telugu', value: 'Strongly Agree' },

    { text: 'Agree', language: 'English', value: 'Agree' },
    { text: 'सहमत', language: 'Hindi', value: 'Agree' },
    { text: 'सहमत', language: 'Marathi', value: 'Agree' },
    { text: 'સહમત', language: 'Gujrati', value: 'Agree' },
    { text: 'ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೇನೆ', language: 'Kannada', value: 'Agree' },
    { text: 'রাজি', language: 'Bengali', value: 'Agree' },
    { text: 'ஏற்கிறேன்', language: 'Tamil', value: 'Agree' },
    { text: 'అంగీకరిస్తున్నాను', language: 'Telugu', value: 'Agree' },

    { text: 'Neither Agree / Disagree', language: 'English', value: 'Neither Agree / Disagree' },
    { text: 'न तो सहमत / असहमत', language: 'Hindi', value: 'Neither Agree / Disagree' },
    { text: 'ना सहमत/असहमत', language: 'Marathi', value: 'Neither Agree / Disagree' },
    { text: 'ન તો સહમત / અસહમત', language: 'Gujrati', value: 'Neither Agree / Disagree' },
    { text: 'ಒಪ್ಪಿಕೊಳ್ಳುವುದಿಲ್ಲ / ಒಪ್ಪುವುದಿಲ್ಲ', language: 'Kannada', value: 'Neither Agree / Disagree' },
    { text: 'নাও সম্মত / অসম্মতি', language: 'Bengali', value: 'Neither Agree / Disagree' },
    { text: 'உடன்படவில்லை / மறுக்கவில்லை', language: 'Tamil', value: 'Neither Agree / Disagree' },
    { text: 'అంగీకరించడం / అంగీకరించకపోవడం లేదు', language: 'Telugu', value: 'Neither Agree / Disagree' },

    { text: 'Disagree', language: 'English', value: 'Disagree' },
    { text: 'असहमत', language: 'Hindi', value: 'Disagree' },
    { text: 'असहमत', language: 'Marathi', value: 'Disagree' },
    { text: 'અસહમત', language: 'Gujrati', value: 'Disagree' },
    { text: 'ಒಪ್ಪುವುದಿಲ್ಲ', language: 'Kannada', value: 'Disagree' },
    { text: 'கருத்து வேறுபாடு', language: 'Tamil', value: 'Disagree' },
    { text: 'అంగీకరించడం లేదు', language: 'Telugu', value: 'Disagree' },

    { text: 'Strongly Disagree', language: 'English', value: 'Strongly Disagree' },
    { text: 'दृढ़तापूर्वक असहमत', language: 'Hindi', value: 'Strongly Disagree' },
    { text: 'पूर्णपणे असहमत', language: 'Marathi', value: 'Strongly Disagree' },
    { text: ' પુરી રીતે અસહમત', language: 'Gujrati', value: 'Strongly Disagree' },
    { text: 'ಧೃಡವಾಗಿ  ಒಪ್ಪುವುದಿಲ್ಲ', language: 'Kannada', value: 'Strongly Disagree' },
    { text: 'দৃঢ়ভাবে অসম্মতি', language: 'Bengali', value: 'Strongly Disagree' },
    { text: 'முரண்படுகிறோம்', language: 'Tamil', value: 'Strongly Disagree' },
    { text: 'గట్టిగా అంగీకరించడం లేదు', language: 'Telugu', value: 'Strongly Disagree' }
    
  ];
  
  public hardCodedQuestionYesOptions = [{
    'text': 'Reward & Recognition',
    'value': 'Reward & Recognition',
    'language': 'English'
  },
  {
    'text': 'Communication Channels(upward & downward)',
    'value': 'Communication Channels(upward & downward)',
    'language': 'English'
  },
  {
    'text': 'Transparency',
    'value': 'Transparency',
    'language': 'English'
  },
  {
    'text': 'Clear & laid down processes & systems',
    'value': 'Clear & laid down processes & systems',
    'language': 'English'
  },
  {
    'text': 'Teamwork & Coordination',
    'value': 'Teamwork & Coordination',
    'language': 'English'
  },
  {
    'text': 'Flexibility & Adaptability',
    'value': 'Flexibility & Adaptability',
    'language': 'English'
  },
  {
    'text': 'Trust & Empowerment',
    'value': 'Trust & Empowerment',
    'language': 'English'
  },
  {
    'text': 'Learning & Development',
    'value': 'Learning & Development',
    'language': 'English'
  },
  {
    'text': 'Diversity & Inclusion',
    'value': 'Diversity & Inclusion',
    'language': 'English'
  },
  {
    'text': 'Others',
    'value': 'Others',
    'language': 'English'
  },





  {
    'text': 'रिवार्ड और मान्यता (Reward & Recognition)',
    'value': 'Reward & Recognition',
    'language': 'Hindi'
  },
  {
    'text': 'संचार चैनल्स (ऊपर और नीचे) (Communication Channels (upward & downward))',
    'value': 'Communication Channels(upward & downward)',
    'language': 'Hindi'
  },
  {
    'text': 'पारदर्शिता (Transparency)',
    'value': 'Transparency',
    'language': 'Hindi'
  },
  {
    'text': 'स्पष्ट और निर्धारित प्रक्रियाएं और लक्षण (Clear & laid down processes & systems)',
    'value': 'Clear & laid down processes & systems',
    'language': 'Hindi'
  },
  {
    'text': 'टीम वर्क और समन्वय (Teamwork & Coordination)',
    'value': 'Teamwork & Coordination',
    'language': 'Hindi'
  },
  {
    'text': 'लचीलापन और अनुकूलनशीलता (Flexibility & Adaptability)',
    'value': 'Flexibility & Adaptability',
    'language': 'Hindi'
  },
  {
    'text': 'विश्वास और सशक्तीकरण (Trust & Empowerment)',
    'value': 'Trust & Empowerment',
    'language': 'Hindi'
  },
  {
    'text': 'शिक्षण और विकास (Learning & Development)',
    'value': 'Learning & Development',
    'language': 'Hindi'
  },
  {
    'text': 'विविधता और समावेशन (Diversity & Inclusion)',
    'value': 'Diversity & Inclusion',
    'language': 'Hindi'
  },
  {
    'text': 'अन्य (Others)',
    'value': 'Others',
    'language': 'Hindi'
  },



  {
    'text': 'पुरस्कार आणि प्रशंसा (Reward & Recognition)',
    'value': 'Reward & Recognition',
    'language': 'Marathi'
  },
  {
    'text': 'संवाद प्रक्रिया (वरच्या आणि खालच्या दिशेने) (Communication Channels (upward & downward))',
    'value': 'Communication Channels(upward & downward)',
    'language': 'Marathi'
  },
  {
    'text': 'पारदर्शकता (Transparency)',
    'value': 'Transparency',
    'language': 'Marathi'
  },
  {
    'text': 'सुस्पष्ट आणि निर्धारित प्रोसेस आणि सिस्टम (Clear & laid down processes & systems)',
    'value': 'Clear & laid down processes & systems',
    'language': 'Marathi'
  },
  {
    'text': 'टीम वर्क आणि समन्वय (Teamwork & Coordination)',
    'value': 'Teamwork & Coordination',
    'language': 'Marathi'
  },
  {
    'text': 'लवचिकता आणि परिस्थितीनुरूप बदलाव(Flexibility & Adaptability)',
    'value': 'Flexibility & Adaptability',
    'language': 'Marathi'
  },
  {
    'text': 'विश्वास आणि सशक्तीकरण(Trust & Empowerment)',
    'value': 'Trust & Empowerment',
    'language': 'Marathi'
  },
  {
    'text': 'शिक्षण आणि विकास(Learning & Development)',
    'value': 'Learning & Development',
    'language': 'Marathi'
  },
  {
    'text': 'विविधता आणि समावेश(Diversity & Inclusion)',
    'value': 'Diversity & Inclusion',
    'language': 'Marathi'
  },
  {
    'text': 'इतर (Others)',
    'value': 'Others',
    'language': 'Marathi'
  },
  




  {
    'text': 'ಪುರಸ್ಕಾರ ಮತ್ತು ಮನ್ನಣೆ (Reward & Recognition)',
      'value': 'Reward & Recognition',
    'language': 'Kannada'
    },
    {
      'text': 'ಸಂವಹನ ಚಾನೆಲ್‌ಗಳು (ಮೇಲ್ಮುಖ ಮತ್ತು ಕೆಳಮುಖ)(Communication Channels (upward & downward))',
      'value': 'Communication Channels(upward & downward)',
      'language': 'Kannada'
    },
    {
      'text': 'ಪಾರದರ್ಶಕತೆ (Transparency)',
      'value': 'Transparency',
      'language': 'Kannada'
    },
    {
      'text': 'ಸ್ಪಷ್ಟ ಮತ್ತು ನಿಗದಿಸಿದ ಪ್ರಕ್ರಿಯೆಗಳು ಮತ್ತು ಸಿಸ್ಟಮ್‌ಗಳು (Clear & laid down processes & systems)',
      'value': 'Clear & laid down processes & systems',
      'language': 'Kannada'
    },
    {
      'text': 'ತಂಡದ ಕೆಲಸ ಮತ್ತು ಸಹಕಾರ (Teamwork & Coordination)',
      'value': 'Teamwork & Coordination',
      'language': 'Kannada'
    },
    {
      'text': 'ಫ್ಲೆಕ್ಸಿಬಿಲಿಟಿ ಮತ್ತು ಅಳವಡಿಸಿಕೊಳ್ಳುವಿಕೆ (Flexibility & Adaptability)',
      'value': 'Flexibility & Adaptability',
      'language': 'Kannada'
    },
    {
      'text': 'ವಿಶ್ವಾಸ ಮತ್ತು ಸಬಲೀಕರಣ (Trust & Empowerment)',
      'value': 'Trust & Empowerment',
      'language': 'Kannada'
    },
    {
      'text': 'ಕಲಿಕೆ ಮತ್ತು ಅಭಿವೃದ್ಧಿ (Learning & Development)',
      'value': 'Learning & Development',
      'language': 'Kannada'
    },
    {
      'text': 'ವೈವಿಧ್ಯತೆ ಮತ್ತು ಸಮಗ್ರತೆ (Diversity & Inclusion)',
      'value': 'Diversity & Inclusion',
      'language': 'Kannada'
    },
    {
      'text': 'ಇತರೆ (Others)',
      'value': 'Others',
      'language': 'Kannada'
    },



    {
      'text': 'ரிவார்டு & அங்கீகாரம் (Reward & Recognition)',
      'value': 'Reward & Recognition',
      'language': 'Tamil'
    },
    {
      'text': 'தொடர்பு சேனல்கள் (அனுப்புதல் மற்றும் பெறுதல்) (Communication Channels (upward & downward))',
      'value': 'Communication Channels(upward & downward)',
      'language': 'Tamil'
    },
    {
      'text': 'வெளிப்படைத்தன்மை (Transparency)',
      'value': 'Transparency',
      'language': 'Tamil'
    },
    {
      'text': 'சீர் செய்து செயல்முறைகள் மற்றும் அமைப்புகளை நிறுவுதல் (Clear & laid down processes & systems)',
      'value': 'Clear & laid down processes & systems',
      'language': 'Tamil'
    },
    {
      'text': 'குழுப்பணி மற்றும் ஒருங்கிணைப்பு (Teamwork & Coordination)',
      'value': 'Teamwork & Coordination',
      'language': 'Tamil'
    },
    {
      'text': 'நெகிழ்வுத்தன்மை மற்றும் தகவமைப்பு (Flexibility & Adaptability)',
      'value': 'Flexibility & Adaptability',
      'language': 'Tamil'
    },
    {
      'text': 'நம்பிக்கை மற்றும் அதிகாரம் (Trust & Empowerment)',
      'value': 'Trust & Empowerment',
      'language': 'Tamil'
    },
    {
      'text': 'கற்றல் மற்றும் மேம்பாடு (Learning & Development)',
      'value': 'Learning & Development',
      'language': 'Tamil'
    },
    {
      'text': 'பன்முகத்தன்மை மற்றும் சேர்க்கை (Diversity & Inclusion)',
      'value': 'Diversity & Inclusion',
      'language': 'Tamil'
    },
    {
      'text': 'மற்றவைகள் (Others)',
      'value': 'Others',
      'language': 'Tamil'
    },




    {
      'text': 'రివార్డు మరియు గుర్తింపు (Reward & Recognition)',
      'value': 'Reward & Recognition',
      'language': 'Telugu'
    },
    {
      'text': 'కమ్యూనికేషన్ చానల్స్ (పైకి మరియు కిందకు) (Communication Channels (upward & downward))',
      'value': 'Communication Channels(upward & downward)',
      'language': 'Telugu'
    },
    {
      'text': 'పారదర్శకత (Transparency)',
      'value': 'Transparency',
      'language': 'Telugu'
    },
    {
      'text': 'స్పష్టమైన మరియు పొందుపరిచిన ప్రక్రియలు మరియు వ్యవస్థలు (Clear & laid down processes & systems)',
      'value': 'Clear & laid down processes & systems',
      'language': 'Telugu'
    },
    {
      'text': 'సమిష్టి క్రుషి మరియు సమన్వయం (Teamwork & Coordination)',
      'value': 'Teamwork & Coordination',
      'language': 'Telugu'
    },
    {
      'text': 'ఫ్లెక్సిబిలిటి మరియు అడాప్టబిలిటి (Flexibility & Adaptability)',
      'value': 'Flexibility & Adaptability',
      'language': 'Telugu'
    },
    {
      'text': 'విశ్వాసం మరియు సాధికారికత (Trust & Empowerment)',
      'value': 'Trust & Empowerment',
      'language': 'Telugu'
    },
    {
      'text': 'నేర్చుకొనుట మరియు అభివ్రుద్ధి (Learning & Development)',
      'value': 'Learning & Development',
      'language': 'Telugu'
    },
    {
      'text': 'వైవిధ్యం మరియు ఇన్ క్లూజన్ (Diversity & Inclusion)',
      'value': 'Diversity & Inclusion',
      'language': 'Telugu'
    },
    {
      'text': 'ఇతరవి (Others)',
      'value': 'Others',
      'language': 'Telugu'
    }



  ];

  public phoneNo = '';
  public userCode = '';
  public responseVia = 'Web';

  public RemainingTime;
  public actualTime;
  public surveyData: any;
  public isSubmitted = false;
  public surveyLinkId = '';
  public invitationDetails: any;
  public currentQuestionNumber = 1;
  public defaultColor = 'rgb(245, 128, 38)';
  public defaultBtnBgColor = 'rgba(245, 128, 38, 0.2)';
  public boxedOptionFontSize = '1rem';

  public currentStatus: 'already-completed' | 'invalid-link' | 'survey-closed' | 'survey-completed' | 'select-language' | 'instruction' | 'question-answer' | 'timed-out';

  public selectedlanguage = '';
  
  public disableScrollToNextQuestionOnPressingEnter = false;

  languages = [
    { value: 'English', viewValue: 'English' },
    { value: 'Hindi', viewValue: 'हिंदी' },
    { value: 'Marathi', viewValue: 'मराठी' },
    { value: 'Kannada', viewValue: 'ಕನ್ನಡ' },
    { value: 'Bengali', viewValue: 'Bengali' },
    { value: 'Gujrati', viewValue: 'ગુજરાતી' },
    { value: 'Tamil', viewValue: 'தமிழ்' },
    { value: 'Arabic', viewValue: 'Arabic' },
    { value: 'Telugu', viewValue: 'తెలుగు' },
    { value: 'Malayalam', viewValue: 'മലയാളം' }
  ];

  public surveyQuestionSet: any;

  public surveyInstruction = '';
  public customizedMessageText = '';
  public scrolling = false;

  constructor(private routeParams: ActivatedRoute,
    private appService: AppService,
    private title: Title,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private surveyService: SurveyService,
    private pageScrollService: PageScrollService,
    private _renderer2: Renderer2,
    private router: Router,
    private breakPointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document) {
    this.routeParams.queryParams.subscribe(params => {
      this.phoneNo = params['mobile_number'] || '';
      this.userCode = params['user_id'] || '';
      this.responseVia = params['cl_type'] || 'Web';
    });

    this.routeParams.params.subscribe(
      response => {
        this.surveyLinkId = response.id || '';
        if (this.surveyLinkId == "BKQV9471") {
          this.disableScrollToNextQuestionOnPressingEnter = true;
        }
      });
    
    if (this.phoneNo != '' && this.userCode != '') {
      this.getParticipantLink();
    } else if (this.surveyLinkId != '') {
      this.getSurveyData();
    }
    else {
      this.currentStatus = 'invalid-link';
    }
  }

  ngOnInit() {
    this.invitationDetails = {};
    this.disableTabKey();
  }
  
  getParticipantLink() {
    const participantData = {
        phoneNumber: this.phoneNo,
        userCode: this.userCode,
        surveyCode: this.surveyLinkId
    };

    this.appService.getParticipantLink(participantData).subscribe(
        res => {
            window.location.href = "https://bwassessment.com/" + res['linkId'] + '?cl_type=' + this.responseVia;
            //window.location.href = "http://localhost:4200/" + res['linkId'] + '?cl_type=' + this.responseVia;
        },
        error => {
            this.currentStatus = 'invalid-link';
        }
    );
}


  ngOnChanges() {
  }

  private disableTabKey() {
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
  document.onkeydown = function (t) {
    if (t.which == 9) {
      return false;
    }
  }`;
    this._renderer2.appendChild(this.document.body, script);
  }

  checkIsMobile() {
    // if (window.innerWidth > window.innerHeight) {
    //   return false;
    // } else {
    // }
    return this.breakPointObserver.isMatched('(max-width: 768px)');

  }

  public getSurveyData() {
    this.appService.getSurveyByLinkId(this.surveyLinkId).subscribe(
      response => {
        if (response['message'] === 'already completed') {
          this.currentStatus = 'already-completed';
        } else if (response['message'] === 'no') {
          this.currentStatus = 'invalid-link';
        } else if (response['message'] === 'closed') {
          this.currentStatus = 'survey-closed';
        }
        if (response['message'] === 'closed' || response['message'] === 'already completed' || response['message'] === 'no') {
          this.appService.GetSurveyCloseImageByLinkId(this.surveyLinkId).subscribe(
            res => {
              if (response['message'] === 'not-found') {
                this.currentStatus = 'invalid-link';
              } else {
                this.surveyData = res;
              }
            },
            error => {
              this.currentStatus = 'invalid-link';
            });
        } else {
          this.surveyData = response;
          if (this.surveyData.showTimer) {
            this.actualTime = this.surveyData.timerDuration * 60
          }
         
          if (response['useNewDesign'] !== true) {
            this.router.navigate(['d', this.surveyLinkId]);
          }
          this.currentStatus = 'select-language';
          if (this.surveyData.colorCode && this.surveyData.colorCode !== '') {
            this.defaultColor = 'rgb(' + this.surveyData.colorCode + ')';
            this.defaultBtnBgColor = 'rgba(' + this.surveyData.colorCode + ', 0.2)';

            this.sanitizer.bypassSecurityTrustStyle("--default-color: " + this.defaultColor);
            this.sanitizer.bypassSecurityTrustStyle("--default-btn-bg-color: " + this.defaultBtnBgColor);
          }

          if (!this.surveyData.isAnonymous) {
            if (this.surveyData.linkId === this.surveyLinkId) {
              this.currentStatus = 'invalid-link';
            }
          }

          if (!this.surveyData.isWeb) {
            this.currentStatus = 'invalid-link';
          }

          if (this.surveyData.title) {

            this.title.setTitle(this.surveyData.title);
          }

          if (this.surveyData.languages.length > 0) {
            this.selectedlanguage = this.surveyData.languages[0];
          }

          if (this.surveyData.customizedMessage && !this.surveyData.isAnonymous) {
            this.getInvitationDetails();
          }

          if (this.currentStatus = 'select-language') {
            this.getQuestionSet();
          }
        }
      },
      error => {
        this.currentStatus = 'invalid-link';
      });
  }

  public getInvitationDetails() {
    this.appService.getInvitationDetails(this.surveyLinkId).subscribe(
      response => {
        this.invitationDetails = response;
        // this.getCustomizedMessage();
      }
    );
  }

  public getLanguageTextFromLanguage(lang) {
    let text = '';
    text = this.languages.find(d => d.value === lang).viewValue;
    return text;
  }

  public getQuestionSet() {
    this.appService.getSurveyQuestionSet(this.surveyData.uId).subscribe(
      response => {
        this.surveyQuestionSet = response;

        this.surveyQuestionSet.questions.forEach(question => {
          question.response = '';
          // question.options.forEach(option => {
          //   option.checked = false;
          // });
        });

        // this.sortQuestionsByOrder();
        // if not anonymousvey get responses
        if (!this.surveyData.isAnonymous) {
          this.getParticipantResponses();
        }
      },
      error => {
        console.log('Error in getting question set');
        console.log(error);
      });
  }

  // private sortQuestionsByOrder() {
  //   this.surveyQuestionSet.questions = this.surveyQuestionSet.questions.sort((a, b) => {
  //     return Number(a.order) > Number (b.order);
  //   });
  // }

  private getParticipantResponses() {
    this.appService.getSurveyQuestionSetResponseByInvitationCode(this.surveyLinkId).subscribe(
      response => {
        let surveyData: any = response;
        if (response != null) {
          let qResponses = response['responses'];
          qResponses.forEach(responseElement => {
            const selectedQuestion = this.surveyQuestionSet.questions.find(q => q.code === responseElement.questionCode);
            if (responseElement.responseCode !== '' && responseElement.responseCode !== null) {
              if (selectedQuestion != null) {
                selectedQuestion.response = responseElement.responseCode;
                if (selectedQuestion.type === 'multi-text-mandatory') {
                  let responses = responseElement.responseCode.split('#');
                  for (let i = 0; i < selectedQuestion.options.length; i++) {
                    selectedQuestion.options[i].response = responses[i];
                  }
                }
              }
            }
          });
          if (surveyData.timeElapsed !== "" && surveyData.timeElapsed !== undefined) {
            this.actualTime = surveyData.timeElapsed * 60
          }
          else {
            this.actualTime = this.surveyData.timerDuration * 60
          }
        }
      },
      error => {
        console.log('Error in getting participant responses');
        console.log(error);
      });
  }



  public viewInstructions() {
    if (this.surveyData.showInstructions) {
      this.surveyInstruction = this.surveyData.instructionContents.find(d => d.language === this.selectedlanguage).text;
      this.currentStatus = 'instruction';
      if (this.selectedlanguage == 'Kannada' || this.selectedlanguage == 'Tamil' || this.selectedlanguage == 'Telugu') {
        this.boxedOptionFontSize = '0.8rem';
        this.sanitizer.bypassSecurityTrustStyle("--boxed-option-font-size: " + this.boxedOptionFontSize);
      }
    } else {
      this.viewQuestions();
    }
  }

  public viewQuestions() {
    let currentQuestionNumber = 1;
    if (!this.surveyData.isAnonymous) {
      for (let i = 0; i < this.surveyQuestionSet.questions.length; ++i) {
        if (!this.isQuestionDisabled(i)) {
          currentQuestionNumber = i + 1;
        }
      }
    }
    this.currentStatus = 'question-answer';
    if (currentQuestionNumber !== 1 && currentQuestionNumber!== this.surveyQuestionSet.questions.length) {
      this.scrollToQuestion(currentQuestionNumber + 1);
    } else {
      window.scrollTo(0, 0);
    }
  }

  is2ColumnActive(question) {
    let active = true;
    question.options.forEach(option => {
      let text = this.getQuestionOptionText(question, option);
      if (text.length > this.surveyData.maxOptionCharLength) {
        active = false;
      }
    });
    return active;
  }

  getQuestionText(question: SurveyQuestionModel) {
    let engQuestionText = '';
    let langQuestionText = '';
    let questionText = '';
    if (question.dependentOn > 0) {
      if (this.selectedlanguage !== 'English') {
        if (question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
          langQuestionText = question.texts.find(t => t.language === this.selectedlanguage && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response).text;
        }
      }
      if (question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response) !== undefined) {
        engQuestionText = question.texts.find(t => t.language === 'English' && t.ifPreviousAnswer === this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response).text;
      }

      if (this.selectedlanguage !== 'English' && langQuestionText !== '') {
        questionText = langQuestionText + "<br><span class='take-survey-eng-question-text'> (" + engQuestionText + ") </span>"
      } else {
        questionText = engQuestionText;
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        langQuestionText = question.texts.find(t => t.language === this.selectedlanguage).text;
      }

      engQuestionText = question.texts.find(t => t.language === 'English').text;

      if (this.selectedlanguage !== 'English' && langQuestionText !== '') {
        questionText = langQuestionText + "<br><span class='take-survey-eng-question-text'> (" + engQuestionText + ") </span>"
      } else {
        questionText = engQuestionText;
      }
    }
    return questionText;
  }

  clearDependentAnswer(question) {
    if (this.surveyQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order) !== undefined) {
      this.surveyQuestionSet.questions.find(q => q.dependentOn > 0 && q.dependentOn === question.order).response = '';
    }
  }



  ngOndestroy() {
    // if (this.continuousFunction) {
    //   clearInterval(this.continuousFunction);
    // }
  }


  getQuestionOptionText(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    let otherLanguageOptionText = '';
    let englishOptionText = '';
    if (question.dependentOn > 0) {
      if (this.surveyQuestionSet.questions.find(q => q.order === question.dependentOn).response !== option.ifPreviousAnswer) {
        return '';
      } else {
        if (this.selectedlanguage !== 'English') {
          otherLanguageOptionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text;
        }
        englishOptionText = option.languageOptions.find(o => o.language === 'English').text;

        if (this.selectedlanguage !== 'English' && otherLanguageOptionText !== '') {
          if (question.type === 'single' && question.questionStyle === 'boxed') {
            optionText = otherLanguageOptionText + "<br><span class='take-survey-eng-option-text'> (" + englishOptionText + ") </span>"
          } else {
            optionText = otherLanguageOptionText + "<span class='take-survey-eng-option-text'> (" + englishOptionText + ") </span>"
          }
        } else {
          optionText = englishOptionText;
        }
        return optionText;
      }
    } else {
      if (this.selectedlanguage !== 'English') {
        otherLanguageOptionText = option.languageOptions.find(o => o.language === this.selectedlanguage).text.toString();
      }
      englishOptionText = option.languageOptions.find(o => o.language === 'English').text.toString();
      if (this.selectedlanguage !== 'English' && otherLanguageOptionText !== '') {
        // optionText = '\u202A' + otherLanguageOptionText + '\u202C' + "  ( " + englishText + " )";
        // optionText = otherLanguageOptionText + "<span class='take-survey-eng-option-text'> (" + englishOptionText + ") </span>";
        if (question.type === 'single' && question.questionStyle === 'boxed') {
          optionText = otherLanguageOptionText + "<br><span class='take-survey-eng-option-text'> (" + englishOptionText + ") </span>"
        } else {
          optionText = otherLanguageOptionText + "<span class='take-survey-eng-option-text'> (" + englishOptionText + ") </span>"
        }
      } else {
        optionText = englishOptionText;
      }
      return optionText;
    }
  }

  getQuestionOptionValue(option: QuestionOption) {
    let optionValue = '';
    optionValue = option.languageOptions.find(o => o.language === 'English').text;
    return optionValue;
  }


  getQuestionOptionTextInEnglish(question: SurveyQuestionModel, option: QuestionOption) {
    let optionText = '';
    optionText = optionText + option.languageOptions.find(o => o.language === 'English').text;
    return optionText;
  }

  checkboxOptionChanged(e, q, o) {
    console.log(e, q, o);
    let selectedValue = this.getQuestionOptionValue(o);
    if (e.checked) {
      if (q.response !== undefined && q.response.trim() !== '' && q.response !== null) {
        q.response = q.response + selectedValue + '##';
      } else {
        q.response = selectedValue + '##';
      }
    } else {
      q.response = q.response.replace(selectedValue + '##', '');
    }
  
    if (this.isMaxReached(q)) {
      this.scrollToQuestion(this.currentQuestionNumber + 1);
    }
  }

  multipleOptionDropdownChanged(event, question) {
    question.response = event.value.join('##') + "##";
    console.log(event);
    console.log(this.surveyQuestionSet.questions);
    if (this.isMaxReached(question)) {
      this.scrollToQuestion(this.currentQuestionNumber + 1);
    }
  }

  multipleOptionDropdownChanged1(event, question, msl) {
    this.hardCodedQuestionMCQAns = event.value.join('##') + "##";
    if (event.value.length === 3) {
      msl.close();
      if (this.hardCodedQuestionMCQAns.indexOf('Others') == -1) {
        this.scrollToQuestion(this.currentQuestionNumber + 1);
      }
    }
  }

  isMaxReached(question) {
    if (question.response) {
      let count = (question.response.match(/##/g) || []).length;
      if (count === question.maxSelect) {
        return true;
      }
      else {
        return false
      }
    } else {
      return false;
    }
  }

  isMaxReached1() {
    if (this.hardCodedQuestionMCQAns != '') {
      let count = (this.hardCodedQuestionMCQAns.match(/##/g) || []).length;
      if (count === 3) {
        return true;
      }
      else {
        return false
      }
    } else {
      return false;
    }
  }

  isThisChecked(q, option) {
    if (q.response !== undefined && q.response.trim() !== '' && q.response !== null) {
      let optionValue = '';
      optionValue = option.languageOptions.find(o => o.language === 'English').text;
      if (q.response.indexOf(optionValue) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }


  isThisChecked1( option) {
    if (this.hardCodedQuestionMCQAns !== '') {
      let optionValue = '';
      optionValue = option.value;
      if (this.hardCodedQuestionMCQAns.indexOf(optionValue) >= 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  markMultitextResponed(question) {
    let responseString = '';
    question.options.forEach(o => {
      if (o.response !== undefined && o.response.trim() !== '' && o.response !== null) {
        responseString = responseString + o.response + '#';
      } else {
        if (responseString !== '') {
          responseString = responseString + ' ' + '#';
        }
      }
    });
    question.response = responseString;
  }

  checkScrollToQuestion(index) {
    let question = this.surveyQuestionSet.questions[index - 2];
    var scroll = true;
    if (index == this.hardCodedQuestionNo +1) {
      this.hardCodedQuestionAns  = question.response;
      if (question.response == "Yes") {
        scroll = false;
      }
    }
    if (question.response === 'other' || question.response === 'Other' || question.response === 'others' || question.response === 'Others' || question.response.indexOf('Others##') > -1) {
      if (question.otherResponseText === undefined || question.otherResponseText === null || question.otherResponseText === "") {
        scroll = false;
      }
    }
    if (scroll) {
      this.scrollToQuestion(index);
    }
  }


  emailchanged(email, index) {
    console.log(email);
    if (email.valid) {
      this.checkScrollToQuestion(index);
      this.saveAnswers();
    }
  }

  public scrollToQuestion(index) {
    let sectionTitle = this.surveyQuestionSet.questions[index - 1].sectionTitle || '';
    let offset = 200;

    if (this.checkIsMobile()) {
      offset = 150;
    }

    if (sectionTitle === '') {
      if (this.checkIsMobile()) {
        offset = 120;
      } else {
        offset = 250;
      }
    }

    setTimeout(() => {
      this.currentQuestionNumber = index;
      // const element = document.getElementById('question-' + index.toString());
      // element.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // let wrapper = document.getElementById('question-model');
      // let count = element.offsetTop - wrapper.scrollTop - 100; // xx = any extra distance from top
      // console.log(element, wrapper, count);
      // wrapper.scrollBy({ top: count, left: 0, behavior: 'smooth' });
      //window.scrollTo(0, count);

      // var rect = document.getElementById('question-' + index.toString()).getBoundingClientRect();
      // console.log(rect);
      // window.scrollTo(0, rect.top);
      this.scrolling = true;
      this.pageScrollService.scroll({
        document: this.document,
        scrollOffset: offset,
        duration: 300,
        _interval: 10,
        scrollTarget: '#question-' + index.toString()
      });

      if (this.surveyQuestionSet.questions[index - 1].type === 'text') {
        let elem = "open-text-" + index.toString();
        document.getElementById(elem).focus();
      }
      setTimeout(() => {
        this.scrolling = false;
      }, 500);
    }, 500);
  }

  gotoPreviousQuestion() {
    if (this.currentQuestionNumber > 1) {
      this.scrollToQuestion(this.currentQuestionNumber - 1);
    }
  }

  gotoNextQuestion() {
    this.scrollToQuestion(this.currentQuestionNumber + 1);
  }


  isQuestionDisabled(index) {
    let question = this.surveyQuestionSet.questions[index];
    if ((this.getQuestionText(question).toLowerCase() === 'not applicable') || question.isOptional) {
      return false
    } else if (question.type !== 'multi-text-mandatory') {
      if (question.response === undefined || question.response.trim() === '' || question.response === null) {
        return true;
      } else if (question.response === 'other' || question.response === 'Other' || question.response === 'others' || question.response === 'Others' || question.response.indexOf('Others##') > -1) {
        if (question.otherResponseText === undefined || question.otherResponseText === null || question.otherResponseText.trim() === "") {
          return true;
        }
      }
      if (question.type === 'multiple') {
        let count = (question.response.match(/##/g) || []).length;
        if (count < question.minSelect || count > question.maxSelect) {
          return true;
        }
      }
    } else {
      question.options.forEach(o => {
        if (o.response === undefined || o.response.trim() === '' || o.response === null) {
          return true;
        }
      });
    }
    //temp text
    if (question.order == this.hardCodedQuestionNo) {
      if (question.response == 'Yes') {
        let count = (this.hardCodedQuestionMCQAns.match(/##/g) || []).length;
        if (count < 3) {
          return true;
        }
        else if (this.hardCodedQuestionMCQAns == '' || this.hardCodedQuestionMCQAns == '##' ) {
          return true;
        }
        else if (this.hardCodedQuestionMCQAns.indexOf('Others') > -1 && this.hardCodedQuestionOtherAns == '') {
          return true;
        }
      }
    }
    return false;
  }

  isNextQuestionDisabled(index) {
    // let index = this.currentQuestionNumber - 1;
    // return this.isQuestionDisabled(index);
    //this.currentQuestionNumber
    let nextQuestionDisabled = false;
    for (let i = 0; i < index; i++) {
      if (this.isQuestionDisabled(i)) {
        nextQuestionDisabled = true;
      }
    }
    return nextQuestionDisabled;
  }

  clickedOnDisabledQuestion(index) {
    if (index === this.currentQuestionNumber - 2) {
      this.scrollToQuestion(index + 1);
    } else if (index === this.currentQuestionNumber) {
      if (!this.isNextQuestionDisabled(this.currentQuestionNumber)) {
        this.scrollToQuestion(index + 1);
      }
    }
  }

  myScrollHandler(event) {
    if (!this.scrolling) {
      let currentDiv = 1;
      let currentDiff = 100000;
      this.surveyQuestionSet.questions.forEach((element, index) => {
        let rDiv = document.getElementById("question-reference-div");
        let rRect = rDiv.getBoundingClientRect();
        let rHeight = (rRect.top + rRect.bottom) / 2;

        let qDiv = document.getElementById('question-' + (index + 1).toString());
        let qRect = qDiv.getBoundingClientRect();
        let qTop = qRect.top;
        let qBottom = qRect.bottom;

        let topDiff = rHeight - qTop;
        if (topDiff < 0) {
          topDiff = topDiff * -1;
        }
        let bottomDiff = rHeight - qBottom;
        if (bottomDiff < 0) {
          bottomDiff = bottomDiff * -1;
        }

        if (topDiff < currentDiff) {
          currentDiff = topDiff;
          currentDiv = index + 1;
        }

        if (bottomDiff < currentDiff) {
          currentDiff = rHeight - qBottom;
          currentDiv = index + 1;
        }
      });

      if (currentDiv == 1 || !this.isNextQuestionDisabled(currentDiv - 1)) {
        this.currentQuestionNumber = currentDiv;
      }
    }
  }



  public getCustomizedMessage() {
    let customizedMessageText = '';
    if (this.surveyData.customizedMessage) {
      customizedMessageText = this.surveyData.customizedMessageText;

      customizedMessageText = customizedMessageText.replace('###Name###', this.invitationDetails.name);
      customizedMessageText = customizedMessageText.replace('###Email###', this.invitationDetails.email);
      customizedMessageText = customizedMessageText.replace('###Phone###', this.invitationDetails.phone);
      customizedMessageText = customizedMessageText.replace('###EmployeeCode###', this.invitationDetails.employeeCode);
      customizedMessageText = customizedMessageText.replace('###Department###', this.invitationDetails.department);
      customizedMessageText = customizedMessageText.replace('###Location###', this.invitationDetails.location);
      customizedMessageText = customizedMessageText.replace('###CompanyName###', this.invitationDetails.companyName);
      customizedMessageText = customizedMessageText.replace('###ReportsTo###', this.invitationDetails.reportsTo);
      customizedMessageText = customizedMessageText.replace('###Column1###', this.invitationDetails.column1);
      customizedMessageText = customizedMessageText.replace('###Column2###', this.invitationDetails.column2);
      customizedMessageText = customizedMessageText.replace('###Column3###', this.invitationDetails.column3);
      customizedMessageText = customizedMessageText.replace('###Column4###', this.invitationDetails.column4);
      customizedMessageText = customizedMessageText.replace('###Column5###', this.invitationDetails.column5);
      customizedMessageText = customizedMessageText.replace('###Column6###', this.invitationDetails.column6);
      customizedMessageText = customizedMessageText.replace('###Column7###', this.invitationDetails.column7);
      customizedMessageText = customizedMessageText.replace('###Column8###', this.invitationDetails.column8);
      customizedMessageText = customizedMessageText.replace('###Column9###', this.invitationDetails.column9);
      customizedMessageText = customizedMessageText.replace('###Column10###', this.invitationDetails.column10);
    }
    this.customizedMessageText = customizedMessageText;
  }

  public getProgressbarValue() {
    let completed = 0;
    for (let i = 0; i < this.surveyQuestionSet.questions.length; ++i) {
      if (!this.isQuestionDisabled(i)) {
        completed += 1;
      }
    }
    return completed / this.surveyQuestionSet.questions.length * 100;
  }

  isSubmitEnabled() {
    let shouldSubmitBeEnabled = true;
    for (let i = 0; i < this.surveyQuestionSet.questions.length; ++i) {
      if (this.isQuestionDisabled(i)) {
        shouldSubmitBeEnabled = false;
      }
    }
    return shouldSubmitBeEnabled;
  }

  SubmitTest() {
    if (!this.isSubmitted) {
      this.isSubmitted = true;
      if (this.surveyQuestionSet.questions.every(d => d.response !== '')) {
        let responses: Response[] = [];
        this.surveyQuestionSet.questions.forEach(d => {
          const response = new Response();
          response.questionCode = d.code;
          response.responseCode = d.response;
          response.otherResponseText = d.otherResponseText;
          //temp
          if (d.order == this.hardCodedQuestionNo && d.response == 'Yes') {
            if (this.hardCodedQuestionMCQAns != '') {
              response.responseCode += "##" + this.hardCodedQuestionMCQAns;
            }
            if (this.hardCodedQuestionOtherAns != '') {
              response.responseCode += this.hardCodedQuestionOtherAns;
            }
          }
          responses.push(response);
        });
        let timeTaken = 0;
        if (this.surveyData.showTimer) {
          timeTaken = this.counter.left / 60000
        }
        const responsequestionSet = {
          surveyUId: this.surveyData.uId,
          clientUId: this.surveyData.clientUId,
          invitationCode: '',
          responseVia: this.responseVia,
          responses: responses,
          timeElapsed: timeTaken
        };

        if (!this.surveyData.isAnonymous) {
          responsequestionSet.invitationCode = this.surveyLinkId;
        }

        this.appService.SubmitQuestionSetResponses(responsequestionSet).subscribe(
          response => {
            this.isSubmitted = false;
            this.currentStatus = 'survey-completed';
          }, errorResponse => {
            console.log(errorResponse);
            if (errorResponse.error.message === 'Survey Closed') {
              Swal.fire("Assessment is closed").then(response => {
                console.log("survey closed in swal");
                window.location.reload();
              })
            }
          });
      } else {
        this.isSubmitted = false;
        Swal.fire('Please answer all questions');
      }
    }
  }



  onFinished() {
    this.isSubmitted = true;
    let responses: Response[] = [];
    let timeTaken = 0;
    if (this.surveyData.showTimer) {
      timeTaken = this.counter.left / 60000
    }
    this.surveyQuestionSet.questions.forEach(d => {
      const response = new Response();
      response.questionCode = d.code;
      response.responseCode = d.response;
      response.otherResponseText = d.otherResponseText;
      // temp code
      if (d.order == this.hardCodedQuestionNo && d.response == 'Yes') {
        if (this.hardCodedQuestionMCQAns != '') {
          response.responseCode += "##" + this.hardCodedQuestionMCQAns;
        }
        if (this.hardCodedQuestionOtherAns != '') {
          response.responseCode += this.hardCodedQuestionOtherAns;
        }
      }
      responses.push(response);
    });
    const responsequestionSet = {
      surveyUId: this.surveyData.uId,
      clientUId: this.surveyData.clientUId,
      invitationCode: '',
      responseVia: this.responseVia,
      responses: responses,
      timeElapsed: timeTaken
    };

    if (!this.surveyData.isAnonymous) {
      responsequestionSet.invitationCode = this.surveyLinkId;
    }

    this.appService.timeOutSurvey(responsequestionSet).subscribe(
      response => {
        this.isSubmitted = false;
        this.currentStatus = 'survey-completed';
      }, errorResponse => {
        console.log(errorResponse);
        if (errorResponse.error.message === 'Survey Closed') {
          Swal.fire("Assessment is closed").then(response => {
            console.log("survey closed in swal");
            window.location.reload();
          })
        }
      });
  }

  public saveAnswers() {
    if (!this.loading && (this.currentQuestionNumber % 5 == 0)) {
      if (!this.surveyData.isAnonymous) {
        let timeTaken = 0;
        if (this.surveyData.showTimer) {
          timeTaken = this.counter.left / 60000
        }
        let responses: Response[] = [];
        this.surveyQuestionSet.questions.forEach(d => {
          const response = new Response();
          response.questionCode = d.code;
          response.responseCode = d.response;
          //temp code
          if (d.order == this.hardCodedQuestionNo && d.response == 'Yes') {
            if (this.hardCodedQuestionMCQAns != '') {
              response.responseCode += "##" + this.hardCodedQuestionMCQAns;
            }
            if (this.hardCodedQuestionOtherAns != '') {
              response.responseCode += this.hardCodedQuestionOtherAns;
            }
          }
          responses.push(response);
        });
      
        const responsequestionSet = {
          surveyUId: this.surveyData.uId,
          clientUId: this.surveyData.clientUId,
          invitationCode: '',
          responseVia: this.responseVia,
          responses: responses,
          timeElapsed: timeTaken
        };

        responsequestionSet.invitationCode = this.surveyLinkId;
        this.loading = true;
        this.appService.SaveQuestionSetResponses(responsequestionSet).subscribe(
          response => {
            this.loading = false;
          }, errorResponse => {
            this.loading = false;
            console.log(errorResponse);
            if (errorResponse.error.message === 'Survey Closed') {
              Swal.fire("Assessment is closed").then(response => {
                console.log("survey closed in swal");
                window.location.reload();
              })
            }
          });
      }
    }
  }

  textChanged(q) {
    if (!this.disableScrollToNextQuestionOnPressingEnter) {
      if (this.currentQuestionNumber < this.surveyQuestionSet.questions.length) {
        if (q.response.trim() !== "") {
          this.scrollToQuestion(this.currentQuestionNumber + 1);
        }
      }
    }
  }

  saveAnswersInOpenText(response) {
    if (response !== null && response !== undefined && response !== "") {
      this.saveAnswers();
    }
  }


}

class Response {
  questionCode: string;
  responseCode: string;
  otherResponseText: string;
  timeElapsed: string;
}
