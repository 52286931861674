import { Injectable } from '@angular/core';
@Injectable()
export class SharedService {

    private apiUrl;
    private stsUrl;

    constructor() {
        // this.apiUrl = 'http://localhost:53896';
         this.apiUrl = 'https://ees-api.azurewebsites.net';
        // this.apiUrl = 'https://ees-api-staging.azurewebsites.net';

        this.stsUrl = 'https://ees-sts.azurewebsites.net';
        // this.stsUrl = 'http://localhost:5000';
    }

    public getAPIUrl() {
        return this.apiUrl;
    }

    public getSTSUrl() {
        return this.stsUrl;
    }
}
