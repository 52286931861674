import { NgxUploaderModule } from 'ngx-uploader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TakeSurveyComponent } from './take-survey/take-survey.component';
import { FileValidator } from './survey/upload-final-excel/file-input.validator';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { SharedService } from './shared/shared.service';
import { NgModule } from '@angular/core';
// angular material by prashant
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatTableModule,
  MatCardModule,
  MatInputModule,
  MatRadioModule,
  MatGridListModule,
  MatToolbarModule,
  MatTabsModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatDialogModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatIconModule,
  MatSelectModule,
  MatCheckboxModule,

} from '@angular/material';


// Authentication components
import { AuthGuard } from './route-guards/auth-guard';
import { LoginComponent } from './login/login.component';
import { AppService } from './app.service';

import { CountdownModule } from 'ngx-countdown';
import { SurveyService } from './survey/survey.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TakeSurveyOldComponent } from './take-survey-old/take-survey-old.component';
import { ContentSafeHtmlPipe } from './shared/content-safe-html.pipe';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { SearchComponent } from './search/search.component';
import { KioskComponent } from './kiosk/kiosk.component';
import { LoaderService } from './loader/loader.service';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { ExternalUserDashboardComponent } from './external-user-dashboard/external-user-dashboard.component';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TakeSurveyComponent,
    TakeSurveyOldComponent,
    LoginComponent,
    ContentSafeHtmlPipe,
    FileValidator,
    SearchComponent,
    KioskComponent,
    LoaderComponent,
    ExternalUserDashboardComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
    MatInputModule,
    MatToolbarModule,
    MatTabsModule,
    MatRadioModule,
    MatGridListModule,
    HttpClientModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDialogModule,
    NgxUploaderModule,
    FormsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatIconModule,
    CountdownModule,
    MatSelectModule,
    NgxPageScrollCoreModule,
    MatProgressSpinnerModule,
    ChartsModule,
    MatMenuModule,
    FlexLayoutModule

  ],
  providers: [SharedService, AuthGuard, AppService, SurveyService, LoaderService, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }



