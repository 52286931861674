import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SharedService } from './shared/shared.service';

@Injectable()
export class AppService {
    private apiUrl = '';
    constructor(private httpService: HttpClient, private sharedService: SharedService) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }
    public getSurveyByLinkId(linkId) {
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetByLinkId?id=' + linkId)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getParticipantLink(participantData: { userCode: string, phoneNumber: string, surveyCode: string }): Observable<any> {
        return this.httpService.post<any>(this.apiUrl + '/api/Surveys/getParticipantLink', participantData)
          .pipe(retry(1), catchError(this.handleError));
      }
      

    public getSurveyByKioskLinkId(linkId) {
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetByKioskLinkId?id=' + linkId)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getInvitationDetails(invitationCode) {
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetInvitationDetails?invitationCode=' + invitationCode).pipe(retry(1), catchError(this.handleError));
    }

     public GetSurveyCloseImageByLinkId(linkid) {
         return this.httpService.get(this.apiUrl + '/api/Surveys/GetSurveyCloseImageByLinkId?id=' + linkid).pipe(retry(1), catchError(this.handleError));
    }

    public getSurveyQuestionSet(uid) {
        return this.httpService.get(this.apiUrl + '/api/SurveyQuestionSets/GetBySurveyUId?uId=' + uid).pipe(retry(1), catchError(this.handleError));
    }

    // this method returns list of survey
    public getSurveyList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetAll', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

     // this method returns all client list
    public getClientList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const headers = new Headers();
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return this.httpService.get(this.apiUrl + '/api/Clients/GetAll', httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public getSurveyQuestionSetResponseByInvitationCode(invitationCode) {
        return this.httpService.get(this.apiUrl + '/api/SurveyQuestionSetResponses/GetByInvitationCode?invitationCode=' + invitationCode).pipe(retry(1), catchError(this.handleError));
    }

    public SaveQuestionSetResponses(responsequestionSet) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const formData = JSON.stringify(responsequestionSet);
        return this.httpService.post(this.apiUrl + '/api/SurveyQuestionSetResponses/SaveAnswers', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public SubmitQuestionSetResponses(responsequestionSet) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const formData = JSON.stringify(responsequestionSet);
        return this.httpService.post(this.apiUrl + '/api/SurveyQuestionSetResponses/SubmitAnswers', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getAllReport() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Reports/Get', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }
    timeOutSurvey(responsequestionSet) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const formData = JSON.stringify(responsequestionSet);
        return this.httpService.post(this.apiUrl + '/api/SurveyQuestionSetResponses/TimeOutSurvey', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
}
